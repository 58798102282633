import React, { Fragment } from 'react'
import { iIssue } from 'MyTypes'
import { RootState } from 'typesafe-actions'
import { connect } from 'react-redux'
import { editIssueAsync } from '../../redux/issues/actions'
import { Label } from '../../models'
import { FormikErrors, useFormik } from 'formik'
import { DraftForm, FormGroup, RadioGroup, ThreeColumnGrid, LooseError, ButtonGroup, DangerButton, PrimaryButton } from '../styles'

interface IssueFormInput {
    title: string
    isPinned: boolean
    label: Label[]
}

interface MyProps {
    issue: iIssue
    closeEdit: () => void
}

type Props = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps & MyProps

const EditIssue = (props: Props) => {

    const { issue, user, editIssue, closeEdit } = props

    const { isAdmin } = user

    const initialValues: IssueFormInput = {
        title: issue.title,
        isPinned: issue.isPinned,
        label: issue.label
    }
    
    function validate(values: IssueFormInput) {
        
        let errors: FormikErrors<IssueFormInput> = {}
        
        if (!values.title) {
            
            errors.title = 'Title is required'
        }
        
        if (values.label.length === 0) {
            
            errors.label = `Label's cannot be empty`
        }
        
        return errors
    }
    
    function onSubmit(values: IssueFormInput) {
        
        const { title, isPinned, label } = values
        
        const updatedIssue: iIssue = {
            title, label, isPinned,
            id: issue.id,
            author: issue.author,
            owner: issue.owner,
            isOpen: issue.isOpen
        }

        editIssue(updatedIssue)

        closeEdit()
    }

    const formik = useFormik({ initialValues, validate, onSubmit })

    const { values, touched, errors, handleChange, handleBlur, handleSubmit, setFieldValue } = formik

    const invalidTitle = values.title === ''

    const isTitleError = (touched.title ?? false) && !!errors.title

    const isLabelError = !!errors.label

    const disabled = invalidTitle || isTitleError || isLabelError

    const deadline: Label[] = [Label.LESS_THAN1_WEEK, Label.LESS_THAN3_WEEK, Label.LESS_THAN6_WEEK, Label.LESS_THAN12_WEEK] 

    function removeDeadline() {
        
        const labels = [...values.label]
    
        const updatedLabels = labels.filter(item => !deadline.includes(item))
    
        setFieldValue('label', updatedLabels)
    }

    function onChangeDeadline(stuff: Label) {
        
        const labels = [...values.label]
    
        const updatedLabels = labels.filter(item => !deadline.includes(item))
    
        updatedLabels.push(stuff)
    
        setFieldValue('label', updatedLabels)
    }

    function onChangeTextbox(stuff: Label) {
        
        const labels = [...values.label]

        if (labels.includes(stuff)) {

            const nextValue = labels.filter(item => item !== stuff)

            setFieldValue('label', nextValue)

        } else {

            labels.push(stuff)

            setFieldValue('label', labels)
        }
    }

    return (
        <DraftForm onSubmit={handleSubmit}>
            <FormGroup err={isTitleError}>
                <label htmlFor="titleID">Title</label>
                <input 
                    type="text" 
                    name="title" 
                    id="titleID"
                    aria-invalid={invalidTitle}
                    aria-describedby='titleError'
                    value={values.title}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
                {isTitleError && <strong id='titleError' role='alert'>{errors.title}</strong>}
            </FormGroup>
            {isAdmin &&
                <Fragment>
                    <p>Customer response required</p>
                    <RadioGroup>
                        <label>
                            <input type="radio" name="isPinned" value="true" onChange={() => setFieldValue('isPinned', true)} /> Yes
                        </label>
                        <label>
                            <input type="radio" name="isPinned" value="false" onChange={() => setFieldValue('isPinned', false)}  defaultChecked /> No
                        </label>
                    </RadioGroup>
                    <p>Labels</p>
                    <ThreeColumnGrid>
                        <label>
                            <input type="checkbox" name="label" value={Label.QUEUED} checked={values.label.includes(Label.QUEUED)} onChange={() => onChangeTextbox(Label.QUEUED)} /> Queued
                        </label>
                        <label>
                            <input type="checkbox" name="label" value={Label.BUG} checked={values.label.includes(Label.BUG)} onChange={() => onChangeTextbox(Label.BUG)} /> Bug
                        </label>
                        <label>
                            <input type="checkbox" name="label" value={Label.RESPONSE_REQUIRED} checked={values.label.includes(Label.RESPONSE_REQUIRED)} onChange={() => onChangeTextbox(Label.RESPONSE_REQUIRED)} /> Response Required
                        </label>
                        <label>
                            <input type="checkbox" name="label" value={Label.WORK_IN_PROGRESS} checked={values.label.includes(Label.WORK_IN_PROGRESS)} onChange={() => onChangeTextbox(Label.WORK_IN_PROGRESS)} /> Work In Progress
                        </label>
                        <label>
                            <input type="checkbox" name="label" value={Label.BILLING_ISSUE} checked={values.label.includes(Label.BILLING_ISSUE)} onChange={() => onChangeTextbox(Label.BILLING_ISSUE)} /> Billing Issue
                        </label>
                        <label>
                            <input type="checkbox" name="label" value={Label.FEATURE_REQUEST} checked={values.label.includes(Label.FEATURE_REQUEST)} onChange={() => onChangeTextbox(Label.FEATURE_REQUEST)} /> Feature Request
                        </label>
                        <label>
                            <input type="checkbox" name="label" value={Label.DUPLICATE} checked={values.label.includes(Label.DUPLICATE)} onChange={() => onChangeTextbox(Label.DUPLICATE)} /> Duplicate
                        </label>
                        <label>
                            <input type="checkbox" name="label" value={Label.ISSUE_RESOLVED} checked={values.label.includes(Label.ISSUE_RESOLVED)} onChange={() => onChangeTextbox(Label.ISSUE_RESOLVED)} /> Issue Resolved
                        </label>
                        <label>
                            <input type="checkbox" name="label" value={Label.FORUM_ISSUE} checked={values.label.includes(Label.FORUM_ISSUE)} onChange={() => onChangeTextbox(Label.FORUM_ISSUE)} /> Forum Issue
                        </label>
                        <label>
                            <input type="checkbox" name="label" value={Label.WEBSITE_ISSUE} checked={values.label.includes(Label.WEBSITE_ISSUE)} onChange={() => onChangeTextbox(Label.WEBSITE_ISSUE)} /> Website Issue
                        </label>
                        <label>
                            <input type="checkbox" name="label" value={Label.APP_ISSUE} checked={values.label.includes(Label.APP_ISSUE)} onChange={() => onChangeTextbox(Label.APP_ISSUE)} /> App Issue
                        </label>
                        <label>
                            <input type="checkbox" name="label" value={Label.WONT_FIX} checked={values.label.includes(Label.WONT_FIX)} onChange={() => onChangeTextbox(Label.WONT_FIX)} /> Won't Fix
                        </label>
                    </ThreeColumnGrid>
                    {isLabelError && <LooseError role='alert'>{errors.label}</LooseError>}
                </Fragment>
            }
            <p>Deadline</p>
            <ThreeColumnGrid>
                <label>
                    <input 
                        type="radio" 
                        name="Deadline"
                        value={Label.LESS_THAN1_WEEK}
                        checked={values.label.includes(Label.LESS_THAN1_WEEK)}
                        onChange={() => onChangeDeadline(Label.LESS_THAN1_WEEK)}
                    /> Less than 1 Week
                </label>
                <label>
                    <input 
                        type="radio" 
                        value={Label.LESS_THAN3_WEEK}
                        checked={values.label.includes(Label.LESS_THAN3_WEEK)}
                        onChange={() => onChangeDeadline(Label.LESS_THAN3_WEEK)}
                    /> Less than 3 Week
                </label>
                <label>
                    <input 
                        type="radio" 
                        value={Label.LESS_THAN6_WEEK}
                        checked={values.label.includes(Label.LESS_THAN6_WEEK)}
                        onChange={() => onChangeDeadline(Label.LESS_THAN6_WEEK)}
                    /> Less than 6 Week
                </label>
                <label>
                    <input 
                        type="radio" 
                        value={Label.LESS_THAN12_WEEK}
                        checked={values.label.includes(Label.LESS_THAN12_WEEK)}
                        onChange={() => onChangeDeadline(Label.LESS_THAN12_WEEK)}
                    /> Less than 12 Week
                </label>
                <label>
                    <input 
                        type="radio" 
                        checked={!values.label.some(item => deadline.some(stuff => stuff === item))}
                        onChange={() => removeDeadline()}
                    /> No Deadline
                </label>
            </ThreeColumnGrid>
            <ButtonGroup>
                <DangerButton type='button' onClick={() => closeEdit()}>
                    Cancel
                </DangerButton>
                <PrimaryButton type='submit' disabled={disabled}>
                    Submit
                </PrimaryButton>
            </ButtonGroup>
        </DraftForm>
    )
}

const mapStateToProps = (state: RootState) => ({
    user: state.auth.user
})

const mapDispatchToProps = {
    editIssue: editIssueAsync.request
}

export default connect(mapStateToProps, mapDispatchToProps)(EditIssue)
