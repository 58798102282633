import { Epic } from "redux-observable";
import { RootAction, RootState, Services, isActionOf } from "typesafe-actions";
import { filter, mergeMap, map, catchError } from "rxjs/operators";
import { listCustomersAsync, disableCustomerAsync, enableCustomerAsync, addAdminAsync, removeAdminAsync } from "./actions";
import { from, of } from "rxjs";

const warning: string = 'refresh browser to reset'

export const listCustomersEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { customer }) => 
action$.pipe(
    filter(isActionOf(listCustomersAsync.request)),
    mergeMap(() => 
        from(customer.listCustomers()).pipe(
            map(res => typeof res === 'string' ? listCustomersAsync.failure(res) : listCustomersAsync.success(res)),
            catchError((message: string) => of(listCustomersAsync.failure(warning + message)))
        )
    )
)

export const disableCustomerEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { customer }) => 
action$.pipe(
    filter(isActionOf(disableCustomerAsync.request)),
    mergeMap((action) => 
        from(customer.disableCustomer(action.payload)).pipe(
            map(res => typeof res === 'string' ? disableCustomerAsync.failure(res) : disableCustomerAsync.success(action.payload)),
            catchError((message: string) => of(disableCustomerAsync.failure(warning + message)))
        )
    )
)

export const enableCustomerEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { customer }) => 
action$.pipe(
    filter(isActionOf(enableCustomerAsync.request)),
    mergeMap((action) => 
        from(customer.enableCustomer(action.payload)).pipe(
            map(res => typeof res === 'string' ? enableCustomerAsync.failure(res) : enableCustomerAsync.success(action.payload)),
            catchError((message: string) => of(enableCustomerAsync.failure(warning + message)))
        )
    )
)

export const addAdminEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { customer }) => 
action$.pipe(
    filter(isActionOf(addAdminAsync.request)),
    mergeMap((action) => 
        from(customer.addAdmin(action.payload)).pipe(
            map(res => typeof res === 'string' ? addAdminAsync.failure(res) : addAdminAsync.success(action.payload)),
            catchError((message: string) => of(addAdminAsync.failure(warning + message)))
        )
    )
)

export const removeAdminEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { customer }) => 
action$.pipe(
    filter(isActionOf(removeAdminAsync.request)),
    mergeMap((action) => 
        from(customer.removeAdmin(action.payload)).pipe(
            map(res => typeof res === 'string' ? removeAdminAsync.failure(res) : removeAdminAsync.success(action.payload)),
            catchError((message: string) => of(removeAdminAsync.failure(warning + message)))
        )
    )
)
