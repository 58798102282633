import React, { useRef, useState } from 'react'
import { EditorState, Editor, DraftStyleMap, RichUtils, ContentBlock, DraftHandleValue } from 'draft-js'
import styled from 'styled-components'
import './RichEditorField.css'
import BlockStyleControls from './BlockStyleControls'
import InlineStyleControls from './InlineStyleControls'
import { PrimaryButton } from '../../styles'

const LinkButton = styled(PrimaryButton)`
    margin: 1rem;
`

interface iRootBorder {
    err: boolean
}

const RootBorder = styled.div<iRootBorder>`
    border: 1px solid var(--black);
    ${props => props.err === true ? 'border-color: var(--color-error-fg);' : ''}
    background-color: ${props => props.err === true ? 'var(--color-error-bg-light)' : 'var(--white)'};
    font-size: max(2rem, 16px);
    padding: 1rem 2rem;
    border-radius: 5px;
`

interface iRichEditorField {
    err: boolean
    isReadOnly: boolean
}

const EditorBorder = styled.div<iRichEditorField>`
    border-top: 1px solid #000;
    padding-top: 1rem;
    ${props => props.err === true ? 'border-color: var(--color-error-fg);' : ''}
    .public-DraftEditor-content {

        ${props => props.isReadOnly === false ? 'height: 30vh;' : ''}
        ${props => props.isReadOnly === false ? 'max-width: 88rem;' : ''}
        ${props => props.isReadOnly === false ? 'overflow-x: auto;' : ''}
        ${props => props.isReadOnly === false ? 'overflow-y: scroll;' : ''}
    }
`
interface Props {
    editorState: EditorState
    onChange: (field: string, value: any, shouldValidate?: boolean) => void
    err: boolean
    onFocus: (field: string, isTouched?: boolean, shouldValidate?: boolean) => void
    isReadOnly: boolean
}

const RichEditorField = (props: Props) => {

    const { editorState, err, onFocus, isReadOnly } = props

    const editorRef = useRef<Editor>(null)
    
    const urlRef = useRef<HTMLInputElement>(null)    
    
    const [urlValue, setUrlValue] = useState('')

    const [showUrlInput, setShowUrlInput] = useState(false)

    const styleMap: DraftStyleMap = {
        CODE: {
            backgroundColor: 'rgba(0,0,0, 0.1)',
            display: 'inline-block',
            fontSize: 'max(2rem, 16px)',
            padding: 2,
            paddingRight: 5,
            paddingLeft: 5,
        }
    }

    function onConfirm() {
        
        const contentState = editorState.getCurrentContent()

        const contentStateWithLink = contentState.createEntity(
            'LINK',
            'MUTABLE',
            { url: urlValue }
        )

        const entityKey = contentStateWithLink.getLastCreatedEntityKey()

        const withLink = RichUtils.toggleLink(
            editorState,
            editorState.getSelection(),
            entityKey
        )

        props.onChange('content', withLink)

        setUrlValue('')

        setShowUrlInput(false)
    }

    function onRemoveLink() {
        
        const selection = editorState.getSelection()

        if (!selection.isCollapsed()) {

            props.onChange('content', RichUtils.toggleLink(editorState, selection, null))
        }
    }

    function onAddLink() {
        
        const selection = editorState.getSelection()

        if (!selection.isCollapsed()) {

            const contentState = editorState.getCurrentContent()

            const startKey = editorState.getSelection().getStartKey()

            const startOffset = editorState.getSelection().getStartOffset()

            const blockWithLinkAtBeginning = contentState.getBlockForKey(startKey)

            const linkKey = blockWithLinkAtBeginning.getEntityAt(startOffset)

            let url = ''

            if (linkKey) {

                const linkInstance = contentState.getEntity(linkKey)

                url= linkInstance.getData().url
            }

            setShowUrlInput(true)

            if (url !== '') {

                setUrlValue(url)
            }

            urlRef.current?.focus()
        }
    }

    function onChange(editorState: EditorState) {
        props.onChange('content', editorState)
    }

    function toggleBlockType(blockType: string) {
        
        onChange(RichUtils.toggleBlockType(editorState, blockType))
    }

    function toggleInlineStyle(inlineStyle: string) {
        
        onChange(RichUtils.toggleInlineStyle(editorState, inlineStyle))
    }

    function getBlockStyle(block: ContentBlock): string {
        
        switch (block.getType()) {

            case 'blockquote':
                
                return 'RichEditor-blockquote'
        
            default:
                
                return ''
        }
    }

    function handleKeyCommand(command: string, editorState: EditorState): DraftHandleValue {
        
        const newState = RichUtils.handleKeyCommand(editorState, command)

        if (newState) {

            onChange(newState)

            return 'handled'
        }
        
        return 'not-handled'
    }

    const disabled = !(urlValue.startsWith('https://') || urlValue.startsWith('http://'))

    return (
        <RootBorder err={err}>
            <BlockStyleControls editorState={editorState} onToggle={toggleBlockType} />
            <InlineStyleControls editorState={editorState} onToggle={toggleInlineStyle} />
            <div>
                <LinkButton type='button' onClick={() => onAddLink()}>Add Link</LinkButton>
                <LinkButton type='button' onClick={() => onRemoveLink()}>Remove Link</LinkButton>
            </div>
            {showUrlInput && (
                <div>
                    <input 
                        type="text"
                        value={urlValue}
                        onChange={e => setUrlValue(e.target.value)}
                        ref={urlRef}
                    />
                    <LinkButton type='button' onClick={() => onConfirm()} disabled={disabled}>Confirm</LinkButton>
                </div>
            )}
            <EditorBorder err={err} onClick={() => editorRef.current?.focus()} isReadOnly={isReadOnly}>
                <Editor 
                    editorState={editorState} 
                    onChange={onChange}
                    handleKeyCommand={handleKeyCommand}
                    ref={editorRef}
                    blockStyleFn={getBlockStyle}
                    customStyleMap={styleMap} 
                    spellCheck={true}
                    onFocus={() => {onFocus('content')}}
                />
            </EditorBorder>
        </RootBorder>
    )
}

export default RichEditorField
