// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';

const Label = {
  "RESPONSE_REQUIRED": "responseRequired",
  "WORK_IN_PROGRESS": "workInProgress",
  "BUG": "bug",
  "BILLING_ISSUE": "billingIssue",
  "FEATURE_REQUEST": "featureRequest",
  "DUPLICATE": "duplicate",
  "ISSUE_RESOLVED": "issueResolved",
  "FORUM_ISSUE": "forumIssue",
  "WEBSITE_ISSUE": "websiteIssue",
  "APP_ISSUE": "appIssue",
  "QUEUED": "queued",
  "WONT_FIX": "wontFix",
  "LESS_THAN1_WEEK": "lessThan1Week",
  "LESS_THAN3_WEEK": "lessThan3Week",
  "LESS_THAN6_WEEK": "lessThan6Week",
  "LESS_THAN12_WEEK": "lessThan12Week"
};

const { Issue, Comment } = initSchema(schema);

export {
  Issue,
  Comment,
  Label
};