import React, { useEffect, Suspense, lazy } from 'react'
import { RootState } from 'typesafe-actions'
import { currentUserAsync } from '../redux/auth/actions'
import { connect } from 'react-redux'
import { useLocation, Switch, Route } from 'react-router-dom'
import { RoutePath, ResourceType } from '../types/routePath'
import LoginPage from './auth/LoginPage'
import ForgotPasswordPage from './auth/ForgotPasswordPage'
import NewPasswordPage from './auth/NewPasswordPage'
import { Loader } from './styles'
import ErrorBoundary from './ErrorBoundary'
import Homepage from './dashboard/Homepage'
import PageNotFound from './PageNotFound'
import ProfilePage from './profile/ProfilePage'
import NewIssue from './issues/NewIssue'
import IssueSingle from './issues/IssueSingle'

const CustomerList = lazy(() => import('./customers/CustomerList'))

const CustomerListEnum = lazy(() => import('./CustomerListEnum'))

const IssueListPage = lazy(() => import('./issues/customer/IssueListPage'))

const CustomerIssueList = lazy(() => import('./issues/admin/CustomerIssueList'))

type Props =  ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps

const Routes = (props: Props) => {

    const { user, currentUser } = props

    const location = useLocation()

    useEffect(() => {

        if (!user.username) {

            currentUser()
        }
    }, [user, currentUser])

    if (!user.username) {

        switch (location.pathname) {

            case RoutePath.LOGIN:
                
                return <LoginPage />

            case RoutePath.FORGOT_PASSWORD:

                return <ForgotPasswordPage />

            case RoutePath.NEW_PASSWORD:

                return <NewPasswordPage />
        
            default:

                return <Loader />
        }
    }

    return (
        <ErrorBoundary>
            <Suspense fallback={<Loader />}>
                <Switch>
                    <Route exact path={RoutePath.BASE}>
                        <Homepage />
                    </Route>
                    <Route path={RoutePath.PROFILE}>
                        <ProfilePage />
                    </Route>
                    <Route path={RoutePath.CUSTOMERS}>
                        <CustomerList />
                    </Route>
                    <Route exact path={RoutePath.ISSUES_LIST}>
                        <CustomerListEnum resourceType={ResourceType.ISSUES} />
                    </Route>
                    <Route path={RoutePath.ISSUES_PAGE}>
                        <IssueListPage />
                    </Route>
                    <Route path={RoutePath.CUSTOMER_ISSUES_LIST}>
                        <CustomerIssueList />
                    </Route>
                    <Route path={RoutePath.NEW_ISSUE}>
                        <NewIssue />
                    </Route>
                    <Route path={RoutePath.ISSUE_SINGLE}>
                        <IssueSingle />
                    </Route>
                    <Route>
                        <PageNotFound />
                    </Route>
                </Switch>
            </Suspense>
        </ErrorBoundary>
    )
}

const mapStateToProps = (state: RootState) => ({
    user: state.auth.user
})

const mapDispatchToProps = {
    currentUser: currentUserAsync.request
}

export default connect(mapStateToProps, mapDispatchToProps)(Routes)
