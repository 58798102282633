import { iIssue, iComment } from 'MyTypes';
import { DateTime } from "luxon"

export function medDateFrom(dateString: string): string {
        
    return DateTime.fromISO(dateString).toLocaleString(DateTime.DATE_MED)
}

export function sortIssuesByCreatedAt(issues: iIssue[]) {
    
    return issues.sort((a, b) => {

        const aCreatedAt = a.createdAt

        const bCreatedAt = b.createdAt

        if (aCreatedAt === undefined || bCreatedAt === undefined) return 0

        if (aCreatedAt > bCreatedAt) return -1

        if (aCreatedAt < bCreatedAt) return 1

        return 0
    })
}

export function sortCommentsByCreatedAt(comments: iComment[]) {
    
    return comments.sort((a, b) => {

        const aCreatedAt = a.createdAt

        const bCreatedAt = b.createdAt

        if (aCreatedAt === undefined || bCreatedAt === undefined) return 0

        if (aCreatedAt < bCreatedAt) return -1

        if (aCreatedAt > bCreatedAt) return 1

        return 0
    })
}

export function sortCommentsByUpdatedAt(comments: iComment[]) {
    
    return comments.sort((a, b) => {

        const aUpdatedAt = a.updatedAt

        const bUpdatedAt = b.updatedAt

        if (aUpdatedAt === undefined || bUpdatedAt === undefined) return 0

        if (aUpdatedAt > bUpdatedAt) return -1

        if (aUpdatedAt < bUpdatedAt) return 1

        return 0
    })
}