import React from 'react'
import styled from 'styled-components'

interface Props {
    err: string
}

interface iError {
    err: string
}

const Error = styled.h3<iError>`
    padding: 1rem .5rem;
    margin: 1.5rem 0;
    background-color: var(--color-error-bg-light);
    color: var(--color-error-fg);
    border-radius: 5px;
    ${props => !props.err ? 'display: none;' : ''}
`

const ErrorAlert: React.FC<Props> = ({ err }) => {

    return <Error err={err} role='alert'>{err}</Error>
}

export default ErrorAlert
