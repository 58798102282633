import React, { ReactNode } from 'react'
import { ContentState, CompositeDecorator } from 'draft-js'

interface Props {
    entityKey: string
    children: ReactNode
    contentState: ContentState   
}

const EditorLink = (props: Props) => {

    const { entityKey, children, contentState } = props

    const entity = contentState.getEntity(entityKey)

    const { url } = entity.getData()

    return (
        <a
            href={url}
            title={url}
            target='_blank'
            rel='noopener noreferrer'
        >
            {children}
        </a>
    )
}

const decorator = new CompositeDecorator([
    {
        strategy: (contentBlock, callback, contentState) => {

            if (!contentState) return

            contentBlock.findEntityRanges(character => {

                const entityKey = character.getEntity()

                if (entityKey !== null) {

                    return contentState.getEntity(entityKey).getType() === 'LINK'
                }

                return false

            }, callback)
        },
        component: EditorLink
    }
])

export default decorator