import { createGlobalStyle } from "styled-components";

export const device = {
    // 600px/16px; 1rem = 10px;
    phone: 'screen and (max-width: 37.5em)',
    // 900px
    tablet: 'screen and (max-width: 56.25em)',
    // 1200px
    laptop: 'screen and (max-width: 75em)',
    // 1800px
    uhd: 'screen and (min-width: 112.5em)'
}

export const GlobalStyle = createGlobalStyle`
    :root {
        --black: #000;
        --black-rgb: 0, 0, 0;
        --white: #fff;
        --color-primary: #0054A8;
        --color-link: #0059B3;
        --color-primary-bg: #8FC7FF;
        --color-primary-test: #3399FF;
        --color-error-bg-light: #f8d7da;
        --color-error-bg-dark: #B30000;
        --color-error-fg: #721c24;
        --dark-gray: #999;
        --light-gray: #eee;
        --color-success: #186329;
        --animation-reveal: 1s ease-out;
    }

    *,
    *::after,
    *::before {
        margin: 0;
        padding: 0;
        box-sizing: inherit;
    }

    *:focus {
        border: 2px solid var(--color-link);
    }

    html {
        box-sizing: border-box;

        // 10px/16px * 100; 1rem = 10px;
        font-size: 62.5%; 

        @media ${device.laptop} {
            // 1rem = 8px;
            font-size: 50%;
        }

        @media ${device.tablet} {
            // 1rem = 6px;
            font-size: 37.5%;
        }

        @media ${device.phone} {
            // 1rem = 4px;
            font-size: 25%;
        }

        @media ${device.uhd} {
            // 1rem = 12px;
            font-size: 75%;
        }
    }

    body {
        font-family: 'Noto Sans', sans-serif;
        font-weight: 400;
        font-size: max(16px, 2rem);
        overscroll-behavior: none;
    }

    h1 {
        font-family: 'Playfair Display', serif;
        font-weight: 700;
    }

    a {
        color: var(--color-link);
        text-decoration: none;

        &:focus, &:hover {
            text-decoration: underline;
        }
    }

    ul {
        /* list-style-position: inside; */
        margin-left: 2rem;
    }

    ol {
        margin-left: 2.5rem;
    }
`