import { createAsyncAction, createAction } from "typesafe-actions";
import { Customer } from 'MyTypes'

export const listCustomersAsync = createAsyncAction(
    'LIST_CUSTOMERS_REQUEST',
    'LIST_CUSTOMERS_SUCCESS',
    'LIST_CUSTOMERS_FAILURE'
)<undefined, Customer[], string>()

export const enableCustomerAsync = createAsyncAction(
    'ENABLE_CUSTOMER_REQUEST',
    'ENABLE_CUSTOMER_SUCCESS',
    'ENABLE_CUSTOMER_FAILURE'
)<string, string, string>()

export const disableCustomerAsync = createAsyncAction(
    'DISABLE_CUSTOMER_REQUEST',
    'DISABLE_CUSTOMER_SUCCESS',
    'DISABLE_CUSTOMER_FAILURE'
)<string, string, string>()

export const addAdminAsync = createAsyncAction(
    'ADD_ADMIN_REQUEST',
    'ADD_ADMIN_SUCCESS',
    'ADD_ADMIN_FAILURE'
)<string, string, string>()

export const removeAdminAsync = createAsyncAction(
    'REMOVE_ADMIN_REQUEST',
    'REMOVE_ADMIN_SUCCESS',
    'REMOVE_ADMIN_FAILURE'
)<string, string, string>()

export const activateCustomer = createAction('ACTIVATE_CUSTOMER')<string>()
