import React, { useEffect } from 'react'
import { RootState } from 'typesafe-actions'
import { loginAsync } from '../../redux/auth/actions'
import { object, string } from 'yup'
import { LoginInput } from 'MyTypes'
import { Heading1, PrimaryButton, AuthMain, GridForm, MyTextInput } from '../styles'
import ErrorAlert from '../utilities/ErrorAlert'
import { Formik } from 'formik'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { RoutePath } from '../../types/routePath'


type Props = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps 

const LoginPage: React.FC<Props> = (props) => {

    const { err, isLogin, login } = props

    useEffect(() => {
        
        document.title = 'Login | Apoorv Mote'

    }, [])

    const schema = object<LoginInput>({
        email: string()
            .required('Email is required'),
        password: string()
            .required('Password is required')
    })

    const initialValues: LoginInput = { email: '', password: '' }

    return (
        <AuthMain>
            <Heading1>Please Login</Heading1>
            <ErrorAlert err={err} />
            <Formik
                validationSchema={schema}
                initialValues={initialValues}
                onSubmit={values => {login(values)}}
            >
                {({ values, errors }) => {

                    const disabled = (values.email === '') || (!!errors.email) || (values.password === '') || (!!errors.password) || (isLogin)

                    return (
                        <GridForm>
                            <MyTextInput name='email' type='email' label='Email' errorID='emailError' required />
                            <MyTextInput name='password' type='password' label='Password' errorID='passwordError' required />
                            <PrimaryButton type='submit' disabled={disabled}>
                                {isLogin ? 'Loading...' : 'Login'}
                            </PrimaryButton>
                        </GridForm>
                    )
                }}
            </Formik>
            <Link to={RoutePath.FORGOT_PASSWORD}>Forgot Password</Link>
        </AuthMain>
    )
}

const mapStateToProps = (state: RootState) => ({
    err: state.auth.authError,
    isLogin: state.auth.isLogin
})

const mapDispatchToProps = {
    login: loginAsync.request
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage)
