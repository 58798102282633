import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { RootState } from 'typesafe-actions'
import { signOutAsync } from '../../redux/auth/actions'
import { Main, DangerButton } from '../styles'

type Props = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps

const ProfilePage = (props: Props) => {

    const { signOut } = props

    useEffect(() => {

        document.title = 'Profile | Apoorv Mote'

    }, [])

    return (
        <Main>
            <DangerButton
                onClick={() => signOut()}
            >
                Sign Out
            </DangerButton>        
        </Main>
    )
}

const mapStateToProps = (state: RootState) => ({
    
})

const mapDispatchToProps = {
    signOut: signOutAsync.request
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfilePage)
