import { createAsyncAction } from 'typesafe-actions'
import { LoginInput, User, ForgotPasswordInput, NewPasswordInput } from 'MyTypes'

export const loginAsync = createAsyncAction(
    'CREATE_LOGIN_REQUEST',
    'CREATE_LOGIN_SUCCESS',
    'CREATE_LOGIN_FAILURE'
)<LoginInput, User, string>()

export const currentUserAsync = createAsyncAction(
    'CURRENT_USER_REQUEST',
    'CURRENT_USER_SUCCESS',
    'CURRENT_USER_FAILURE'
)<undefined, User, string>()

export const signOutAsync = createAsyncAction(
    'SIGN_OUT_REQUEST',
    'SIGN_OUT_SUCCESS',
    'SIGN_OUT_FAILURE'
)<undefined, undefined, string>()

export const forgotPasswordAsync = createAsyncAction(
    'FORGOT_PASSWORD_REQUEST',
    'FORGOT_PASSWORD_SUCCESS',
    'FORGOT_PASSWORD_FAILURE'
)<ForgotPasswordInput, undefined, string>()

export const newPasswordAsync = createAsyncAction(
    'NEW_PASSWORD_REQUEST',
    'NEW_PASSWORD_SUCCESS',
    'NEW_PASSWORD_FAILURE'
)<NewPasswordInput, undefined, string>()