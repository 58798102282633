import Auth from '@aws-amplify/auth'
import API from '@aws-amplify/api'
import { Customer, RegisterInput, ConfirmInput, VerifyInput } from 'MyTypes'
import history from '../../history'
import { RoutePath } from '../../types/routePath'

const apiName = 'AdminQueries'

export async function listCustomers() {
    
    const path = '/listUsers'
    
    try {
        
        const myInit = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
            }
        }

        const response = await API.get(apiName, path, myInit)

        const customers: Customer[] = []

        response.Users.forEach((user: any) => {

            const isEnabled: boolean = user.Enabled

            const username: string = user.Username

            const { Attributes } = user

            const isVerified: boolean = Attributes[1].Value === 'true'

            const firstName: string = Attributes[2].Value

            const lastName: string = Attributes[3].Value

            const email: string = Attributes[4].Value

            const isAdmin = Attributes[5].Value === "1"

            const customer: Customer = { isEnabled, isAdmin, isVerified, username, firstName, lastName, email }

            customers.push(customer)
        });

        return customers

    } catch (err) {

        const { message } = err

        return typeof message === 'string' ? message : 'Unknown error'
    }
}

export async function registerCustomer(input: RegisterInput) {
    
    const { email, password, given_name, family_name } = input

    try {

        const response = await Auth.signUp({
            username: email.toLowerCase(),
            password,
            attributes: {
                given_name, 
                family_name,
                "custom:isAdmin": "0"
            }
        })

        history.push({ pathname: RoutePath.CONFIRM, state: { email }})

        const customer: Customer = {
            email,
            firstName: given_name,
            lastName: family_name,
            isAdmin: false,
            isVerified: false,
            username: response.userSub,
            isEnabled: true
        }

        return customer

    } catch(err) {

        const { message } = err

        return typeof message === 'string' ? message : 'Unknown error'
    }
}

export async function confirmCustomer(input: ConfirmInput) {
    
    const { email, code } = input

    try {

        await Auth.confirmSignUp(email, code)

        history.push(RoutePath.CUSTOMERS)

        return

    } catch(err) {

        const { message } = err

        return typeof message === 'string' ? message : 'Unknown error'
    }
}

export async function resendSignUp(input: VerifyInput) {
    
    const { email } = input

    try {

        await Auth.resendSignUp(email.toLowerCase())

        history.push({ pathname: RoutePath.CONFIRM, state: { email }})

        return

    } catch(err) {

        const { message } = err

        return typeof message === 'string' ? message : 'Unknown error'
    }
}

export async function disableCustomer(username: string) {

    const path = '/disableUser'
    
    try {

        const myInit = {
            body: {
                "username": username
            },
            headers: {
                'Content-Type': 'application/json',
                Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
            }
        }

        await API.post(apiName, path, myInit)

        return 

    } catch (err) {

        const { message } = err

        return typeof message === 'string' ? message : 'Unknown error'
    }
}

export async function enableCustomer(username: string) {

    const path = '/enableUser'
    
    try {

        const myInit = {
            body: {
                "username": username
            },
            headers: {
                'Content-Type': 'application/json',
                Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
            }
        }

        await API.post(apiName, path, myInit)

        return 

    } catch (err) {

        const { message } = err

        return typeof message === 'string' ? message : 'Unknown error'
    }
}

export async function addAdmin(username: string) {

    const path = '/addUserToGroup'
    
    try {

        const myInit = {
            body: {
                "username": username,
                "groupname": "admin"
            },
            headers: {
                'Content-Type': 'application/json',
                Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
            }
        }

        await API.post(apiName, path, myInit)

        return 

    } catch (err) {

        const { message } = err

        return typeof message === 'string' ? message : 'Unknown error'
    }
}

export async function removeAdmin(username: string) {

    const path = '/removeUserFromGroup'
    
    try {

        const myInit = {
            body: {
                "username": username,
                "groupname": "admin"
            },
            headers: {
                'Content-Type': 'application/json',
                Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
            }
        }

        await API.post(apiName, path, myInit)

        return 

    } catch (err) {

        const { message } = err

        return typeof message === 'string' ? message : 'Unknown error'
    }
}