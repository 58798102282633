import React, { Component } from 'react'
import { Main, Heading1 } from './styles'

interface Props {
    
}
interface State {
    hasError: boolean
}

export default class ErrorBoundary extends Component<Props, State> {

    constructor(props: Props) {
        super(props)
        
        this.state = { hasError: false }
    }

    static getDerivedStateFromError(err: any) {

        console.log(err)

        return { hasError: true }
    }

    render() {

        if (this.state.hasError) {
            
            return (
                <Main>
                    <Heading1>
                    Failed to load page because internet connection lost. Connect to the internet and try again. 
                    </Heading1>
                </Main>
            )
        }

        return this.props.children
    }
}
