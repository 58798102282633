import React, { useEffect, Fragment } from 'react'
import { RootState } from 'typesafe-actions'
import { connect } from 'react-redux'
import { listCommentAsync, editCommentAsync } from '../../redux/comments/actions'
import { Loader, Main, MarginButton } from '../styles'
import { sortCommentsByUpdatedAt } from '../utilities/helper'
import ReadOnlyEditor from '../utilities/readOnlyEditor/ReadOnlyEditor'
import { convertFromRaw, EditorState } from 'draft-js'
import { listIssueAsync } from '../../redux/issues/actions'
import decorator from '../utilities/EditorLink'

type Props = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps

const Homepage = (props: Props) => {

    const { isFirstComment, listComments, isFirstIssue, listIssues, isFetchingComments, comments, user, customers, editComment } = props

    useEffect(() => {

        document.title = 'Home | Apoorv Mote'

        if (isFirstComment) {

            listComments()
        }

        if (isFirstIssue) {

            listIssues()
        }

    }, [isFirstComment, listComments, isFirstIssue, listIssues])

    if (isFirstComment || isFetchingComments) return <Loader />

    const sortedComments = sortCommentsByUpdatedAt(comments).slice(0, 10)

    if (sortedComments.length === 0) {

        return (
            <Main>
                <MarginButton onClick={() => {listIssues(); listComments();}}>
                    Refresh
                </MarginButton>
                <p>No recent comments available. Start by going to <strong>Issues</strong> and creating <strong>New Issue</strong>.</p>
            </Main>
        )
    }

    return (
        <Main>
            <MarginButton onClick={() => {listIssues(); listComments();}}>
                Refresh
            </MarginButton>
            {sortedComments.map(item => {

                const issue = item.issue

                const contentObject = JSON.parse(item.content)

                const contentState = convertFromRaw(contentObject)

                const editorState = EditorState.createWithContent(contentState, decorator)

                let name: string

                if (user.username === item.author) {

                    name = `${user.firstName} ${user.lastName}`

                } else if (!user.isAdmin) {

                    name = 'admin'

                } else {

                    const patron = customers.find(stuff => stuff.username === item.author)

                    name = `${patron?.firstName ?? 'unknown'} ${patron?.lastName ?? 'unknown'}`
                }

                if (issue) {

                    return (
                        <ReadOnlyEditor 
                            key={item.id}
                            issueID={issue.id}
                            editorState={editorState}
                            name={name}
                            updatedAt={item.updatedAt ?? '1900-01-01'}
                            isAuthor={false}
                            comment={item}
                            editComment={editComment}
                        />
                    )
                }

                return <Fragment key={item.id} />
            })}
        </Main>
    )
}

const mapStateToProps = (state: RootState) => ({
    customers: state.customer.customers,
    isFirstComment: state.comment.isFirstComment,
    isFetchingComments: state.comment.isFetchingComments,
    comments: state.comment.comments,
    user: state.auth.user,
    isFirstIssue: state.issue.isFirstIssue,
})

const mapDispatchToProps = {
    listComments: listCommentAsync.request,
    listIssues: listIssueAsync.request,
    editComment: editCommentAsync.request
}

export default connect(mapStateToProps, mapDispatchToProps)(Homepage)
