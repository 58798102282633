import { createAsyncAction } from "typesafe-actions";
import { newCommentInput, iComment } from "MyTypes";

export const newCommentAsync = createAsyncAction(
    'NEW_COMMENT_REQUEST',
    'NEW_COMMENT_SUCCESS',
    'NEW_COMMENT_FAILURE'
)<newCommentInput, iComment, string>()

export const editCommentAsync = createAsyncAction(
    'EDIT_COMMENT_REQUEST',
    'EDIT_COMMENT_SUCCESS',
    'EDIT_COMMENT_FAILURE'
)<iComment, iComment, string>()

export const removeCommentAsync = createAsyncAction(
    'REMOVE_COMMENT_REQUEST',
    'REMOVE_COMMENT_SUCCESS',
    'REMOVE_COMMENT_FAILURE'
)<string, string, string>()

export const listCommentAsync = createAsyncAction(
    'LIST_COMMENT_REQUEST',
    'LIST_COMMENT_SUCCESS',
    'LIST_COMMENT_FAILURE'
)<undefined, iComment[], string>()

export const listCustomerCommentAsync = createAsyncAction(
    'LIST_CUSTOMER_COMMENT_REQUEST',
    'LIST_CUSTOMER_COMMENT_SUCCESS',
    'LIST_CUSTOMER_COMMENT_FAILURE'
)<string, iComment[], string>()
