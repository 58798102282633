import { compose, applyMiddleware, createStore } from "redux";
import { createEpicMiddleware } from "redux-observable";
import { RootAction, RootState, Services } from "typesafe-actions";
import services from "../services";
import rootReducer from "./root-reducer";
import rootEpic from "./root-epic";

export const composeEnhancers =
  (process.env.NODE_ENV === 'development' &&
    window &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose;

  export const epicMiddleware = createEpicMiddleware<RootAction, RootAction, RootState, Services>({
      dependencies: services
  })

  const enhancers = composeEnhancers(applyMiddleware(epicMiddleware))

  const initialState = {}

  const store = createStore(rootReducer, initialState, enhancers)

  epicMiddleware.run(rootEpic)

  export default store