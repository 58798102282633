import { combineReducers } from 'redux'
import auth from '../auth/reducer'
import customer from '../customers/reducer'
import issue from '../issues/reducer'
import comment from '../comments/reducer'

const rootReducer = combineReducers({
    auth,
    customer,
    issue,
    comment
})

export default rootReducer