import React, { useEffect, useState, Fragment } from 'react'
import { useParams, Link } from 'react-router-dom'
import { RootState } from 'typesafe-actions'
import { connect } from 'react-redux'
import { Main, Heading1, FormGroup, DraftForm, ButtonGroup, DangerButton, PrimaryButton, BadgeList } from '../styles'
import { RoutePath } from '../../types/routePath'
import ReadOnlyEditor from '../utilities/readOnlyEditor/ReadOnlyEditor'
import { convertFromRaw, EditorState, convertToRaw } from 'draft-js'
import { issueComments } from '../../redux/comments/selector'
import { activeIssue, editIssueAsync } from '../../redux/issues/actions'
import { FormikErrors, useFormik, FormikHelpers } from 'formik'
import RichEditorField from '../utilities/richEditor/RichEditorField'
import { newCommentInput, iIssue } from 'MyTypes'
import { newCommentAsync, editCommentAsync } from '../../redux/comments/actions'
import EditIssue from './EditIssue'
import decorator from '../utilities/EditorLink'

interface CommentFormInput {
    content: EditorState
    isOpen: boolean
}

type Props = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps

const IssueSingle = (props: Props) => {

    const { issues, comments, user, activateIssue, newComment, editIssue, customers, editComment } = props

    const { isAdmin } = user

    const [isEditIssue, setIsEditIssue] = useState(false)

    const params = useParams() as any

    const issueID = params.issueID as string

    const issue = issues.find(item => item.id === issueID)

    useEffect(() => {

        document.title = 'Issue Detail | Apoorv Mote'

        if (issue) {

            activateIssue(issue.id)
        }

    }, [activateIssue, issue])

    const initialValues: CommentFormInput = { content: EditorState.createEmpty(decorator), isOpen: issue?.isOpen ?? false }

    function validate(values: CommentFormInput) {
        
        let errors: FormikErrors<CommentFormInput> = {}

        if (!values.content.getCurrentContent().hasText()) {

            errors.content = 'Comment cannot be empty'
        }

        return errors
    }

    function onSubmit(values: CommentFormInput, formikHelpers: FormikHelpers<CommentFormInput>) {
        
        const content = JSON.stringify(convertToRaw(values.content.getCurrentContent()))

        if (issue) {

            const input: newCommentInput = {
                content, 
                owner: issue.owner, 
                author: user.username,
                issueID: issue.id 
            }

            newComment(input)

            if (issue.isOpen !== values.isOpen) {

                const updatedIssue: iIssue = {
                    id: issue.id, 
                    author: issue.author,
                    owner: issue.owner,
                    isOpen: values.isOpen,
                    isPinned: issue.isPinned,
                    createdAt: issue.createdAt,
                    label: issue.label,
                    title: issue.title
                }

                editIssue(updatedIssue)
            }
        }

        formikHelpers.resetForm()
    }

    const formik = useFormik<CommentFormInput>({ initialValues, validate, onSubmit })

    const { values, touched, errors, setFieldValue, handleSubmit, setFieldTouched, submitForm } = formik

    const isContentError = !!touched.content && !!errors.content

    const invalidContent = !values.content.getCurrentContent().hasText()

    const disabled = isContentError || invalidContent

    function onClose() {

        setFieldValue('isOpen', false)
        
        submitForm()
    }
    
    if (issue) {

        return (
            <Main>
                {isEditIssue ? (
                    <EditIssue issue={issue} closeEdit={() => setIsEditIssue(false)} />
                ) : (
                    <Fragment>
                        <Heading1>{issue.title}</Heading1>
                        <BadgeList>
                            {issue.label.map(item => 
                                <li key={item}>{item}</li>
                                )}
                        </BadgeList>
                        <ButtonGroup>
                            {user.username === issue.author && <PrimaryButton onClick={() => setIsEditIssue(true)}>{isAdmin ? 'Edit Title and Label' : 'Edit Title and Priority'}</PrimaryButton>}
                            {isAdmin && <Link to={`${RoutePath.CUSTOMER_ISSUES_BASE}${issue.owner}/1`}>Back to issue list</Link>}
                        </ButtonGroup>
                    </Fragment>
                )}
                {comments.map(item => {
                    
                    const contentObject = JSON.parse(item.content)
                    
                    const contentState = convertFromRaw(contentObject)
                    
                    const editorState = EditorState.createWithContent(contentState, decorator)
                    
                    let name: string

                    if (user.username === item.author) {

                        name = `${user.firstName} ${user.lastName}`

                    } else if (!isAdmin) {

                        name = 'admin'

                    } else {

                        const patron = customers.find(stuff => stuff.username === item.author)

                        name = `${patron?.firstName ?? 'unknown'} ${patron?.lastName ?? 'unknown'}`
                    }

                    return <ReadOnlyEditor 
                        key={item.id} 
                        issueID=''
                        editorState={editorState} 
                        name={name}
                        updatedAt={item.updatedAt ?? '1900-01-01'}  
                        isAuthor={user.username === item.author}
                        comment={item}
                        editComment={editComment}
                    />
                })}
                <DraftForm onSubmit={handleSubmit}>
                    <FormGroup err={isContentError}>
                        <label>Comment</label>
                        <RichEditorField 
                            editorState={values.content}
                            onChange={setFieldValue}
                            err={isContentError}
                            onFocus={setFieldTouched}
                            isReadOnly={false}
                        />
                        {isContentError && <strong role='alert'>{errors.content}</strong>}
                    </FormGroup>
                    {values.isOpen ? (
                        <ButtonGroup>
                            <DangerButton type='button' onClick={onClose} disabled={disabled}>
                                Comment and Close
                            </DangerButton>
                            <PrimaryButton type='submit' disabled={disabled}>
                                Comment
                            </PrimaryButton>
                        </ButtonGroup>
                    ) : (
                        <PrimaryButton type='submit' disabled={disabled}>
                            Comment
                        </PrimaryButton>
                    )}
                </DraftForm>
            </Main>
        )
    }

    if (isAdmin) {

        return (
            <Main>
                <p>Invalid issue. Please go <Link to={RoutePath.ISSUES_LIST}>back to user list</Link> and select different user and issue.</p>
            </Main>
        )
    }

    return (
        <Main>
            <p>Invalid issue. Please go <Link to={RoutePath.ISSUES_PAGE_1}>back to issue list</Link> and select different issue</p>
        </Main>
    )
}

const mapStateToProps = (state: RootState) => ({
    customers: state.customer.customers,
    issues: state.issue.issues,
    comments: issueComments(state),
    user: state.auth.user
})

const mapDispatchToProps = {
    activateIssue: activeIssue,
    newComment: newCommentAsync.request,
    editIssue: editIssueAsync.request,
    editComment: editCommentAsync.request
}

export default connect(mapStateToProps, mapDispatchToProps)(IssueSingle)
