import { iComment } from "MyTypes";
import { createReducer } from "typesafe-actions";
import { newCommentAsync, editCommentAsync, removeCommentAsync, listCommentAsync, listCustomerCommentAsync } from "./actions";
import { signOutAsync } from "../auth/actions";
import { combineReducers } from "redux";
import { newIssueAsync } from "../issues/actions";

const initialComments: iComment[] = []

const comments = createReducer(initialComments)
.handleAction([newCommentAsync.success], (state, action) => [...state, action.payload])
.handleAction([newIssueAsync.success], (state, action) => [...state, action.payload.comment])
.handleAction([editCommentAsync.success], (state, action) => {
    const comments = [...state]
    const index = comments.findIndex(item => item.id === action.payload.id)
    if (index === -1) return state
    comments[index] = action.payload
    return comments
})
.handleAction([removeCommentAsync.success], (state, action) => [...state].filter(item => item.id !== action.payload))
.handleAction([listCommentAsync.success], (state, action) => action.payload)
.handleAction([listCustomerCommentAsync.success], (state, action) => action.payload)
.handleAction([signOutAsync.success], () => initialComments)

const isFetchingComments = createReducer(false)
.handleAction([listCommentAsync.request, listCustomerCommentAsync.request], () => true)
.handleAction([listCommentAsync.success, listCustomerCommentAsync.success, listCommentAsync.failure, listCustomerCommentAsync.failure], () => false)

const isFirstComment = createReducer(true)
.handleAction([listCommentAsync.success], () => false)
.handleAction([signOutAsync.success], () => true)

const isNewEditComment = createReducer(false)
.handleAction([newCommentAsync.request, editCommentAsync.request], () => true)
.handleAction([newCommentAsync.success, editCommentAsync.success, newCommentAsync.failure, editCommentAsync.failure], () => false)

const emptyError: string = ''

const commentError = createReducer(emptyError)
.handleAction([newCommentAsync.failure, editCommentAsync.failure, removeCommentAsync.failure, listCommentAsync.failure, listCustomerCommentAsync.failure], (state, action) => action.payload)
.handleAction([newCommentAsync.success, editCommentAsync.success, removeCommentAsync.success, listCommentAsync.success, listCustomerCommentAsync.success], () => emptyError)

const comment = combineReducers({
    comments,
    isFetchingComments,
    isFirstComment,
    isNewEditComment,
    commentError
})

export default comment