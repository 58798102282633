import React, { SyntheticEvent } from 'react'
import styled from 'styled-components'

interface Props {
    active: boolean
    style: string
    label: string
    onToggle: (style: string) => void
}

interface iStyleSpan {
    active: boolean
}

const StyleSpan = styled.span<iStyleSpan>`
    color: ${props => props.active ?  '#fff' : '#000'};
    background-color: ${props => props.active ? '#000' : '#fff'};
    cursor: pointer;
    padding: 5px;
    display: inline-block;
    margin-right: 1rem;
    border: 1px solid #000;
    margin-bottom: .5rem;
    
    &:hover {
        background-color: ${props => props.active ?  '#fff' : '#000'};
        color: ${props => props.active ? '#000' : '#fff'};
    }
`

const StyleButton = (props: Props) => {

    const { active, onToggle, style, label } = props

    function onMouseDown(event: SyntheticEvent<{}>) {
        
        event.preventDefault()

        onToggle(style)
    }

    return (
        <StyleSpan active={active} onMouseDown={onMouseDown}>
            {label}
        </StyleSpan>
    )
}

export default StyleButton
