import { iIssue } from 'MyTypes';
import { createReducer } from 'typesafe-actions';
import { newIssueAsync, editIssueAsync, listIssueAsync, listCustomerIssueAsync, activeIssue } from './actions';
import { signOutAsync } from '../auth/actions';
import { combineReducers } from 'redux';

const initialIssues: iIssue[] = []

const issues = createReducer(initialIssues)
.handleAction([newIssueAsync.success], (state, action) => [...state, action.payload.issue])
.handleAction([editIssueAsync.success], (state, action) => {
    const issues = [...state]
    const index = issues.findIndex(item => item.id === action.payload.id)
    if (index === -1) return state
    issues[index] = action.payload
    return issues
})
.handleAction([listIssueAsync.success, listCustomerIssueAsync.success], (state, action) => action.payload)
.handleAction([signOutAsync.success], () => initialIssues)

const isFetchingIssues = createReducer(false)
.handleAction([listIssueAsync.request, listCustomerIssueAsync.request], () => true)
.handleAction([listIssueAsync.success, listIssueAsync.failure, listCustomerIssueAsync.success, listCustomerIssueAsync.failure], () => false)

const isFirstIssue = createReducer(true)
.handleAction([listIssueAsync.success], () => false)
.handleAction([signOutAsync.success], () => true)

const isNewEditIssue = createReducer(false)
.handleAction([newIssueAsync.request, editIssueAsync.request], () => true)
.handleAction([newIssueAsync.success, newIssueAsync.failure, editIssueAsync.success, editIssueAsync.failure], () => false)

const emptyError: string = ''

const issueError = createReducer(emptyError)
.handleAction([newIssueAsync.failure, editIssueAsync.failure, listIssueAsync.failure, listCustomerIssueAsync.failure], (state, action) => action.payload)
.handleAction([newIssueAsync.success, editIssueAsync.success, listIssueAsync.success, listCustomerIssueAsync.success], (state, action) => emptyError)

const activeIssueReducer = createReducer(emptyError)
.handleAction([activeIssue], (state, action) => action.payload)

const issue = combineReducers({
    issues,
    isFetchingIssues,
    isFirstIssue,
    isNewEditIssue,
    issueError,
    activeIssue: activeIssueReducer
})

export default issue