import { Epic } from 'redux-observable'
import { RootAction, RootState, Services, isActionOf } from 'typesafe-actions'
import { filter, switchMap, catchError, map, mergeMap } from 'rxjs/operators'
import { loginAsync, signOutAsync, currentUserAsync, forgotPasswordAsync, newPasswordAsync } from './actions'
import { from, of } from 'rxjs'

const warning: string = 'refresh the browser to reset'

export const loginEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { auth }) => 
action$.pipe(
    filter(isActionOf(loginAsync.request)),
    switchMap((action) =>
        from(auth.login(action.payload)).pipe(
            map(res => typeof res === 'string' ? loginAsync.failure(res) : loginAsync.success(res)),
            catchError((message: string) => of(loginAsync.failure(warning + message)))
        )
    )
)

export const signOutEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { auth }) => 
action$.pipe(
    filter(isActionOf(signOutAsync.request)),
    mergeMap(() => 
        from(auth.signOut()).pipe(
            map(res => res ? signOutAsync.failure(res) : signOutAsync.success()),
            catchError((message: string) => of(signOutAsync.failure(warning + message)))
        )
    )
)

export const currentUserEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { auth }) => 
action$.pipe(
    filter(isActionOf(currentUserAsync.request)),
    switchMap(() => 
        from(auth.currentUser()).pipe(
            map(res => typeof res === 'string' ? currentUserAsync.failure(res) : currentUserAsync.success(res)),
            catchError((message: string) => of(currentUserAsync.failure(warning + message)))
        )
    )
)

export const forgotPasswordEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { auth }) =>
action$.pipe(
    filter(isActionOf(forgotPasswordAsync.request)),
    switchMap((action) => 
        from(auth.forgotPassword(action.payload)).pipe(
            map(res => typeof res === 'string' ? forgotPasswordAsync.failure(res) : forgotPasswordAsync.success()),
            catchError((message: string) => of(forgotPasswordAsync.failure(warning + message)))
        )
    )
)

export const newPasswordEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { auth }) => 
action$.pipe(
    filter(isActionOf(newPasswordAsync.request)),
    mergeMap((action) => 
        from(auth.newPassword(action.payload)).pipe(
            map(res => typeof res === 'string' ? newPasswordAsync.failure(res) : newPasswordAsync.success()),
            catchError((message: string) => of(newPasswordAsync.failure(warning + message)))
        )
    )
)