import * as auth from './auth'
import * as customer from './customer'
import * as issue from './issue'
import * as comment from './comment'

export default {
    auth,
    customer,
    issue,
    comment
}