import React from 'react'
import styled from 'styled-components'
import { device, keyframeReveal, NavLink } from './styles'
import { RootState } from 'typesafe-actions'
import { connect } from 'react-redux'
import { RoutePath } from '../types/routePath'
import { signOutAsync } from '../redux/auth/actions'

const Skip = styled.a`
    position: absolute;
    left: -99rem;
    top: auto;
    height: 1px;
    width: 1px;
    overflow: hidden;
    background-color: var(--white);

    &:focus {
        left: auto;
        width: auto;
        height: auto;
        overflow: auto;
        padding: .5rem 1rem;
    }
`

const NavList = styled.ul`
    list-style: none;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;

    @media ${device.phone} {
        flex-direction: column;
    }

    li {
        text-align: center;
        margin: 1rem 0;

        @media ${device.phone} {    
            margin-bottom: 3rem;
        }
    }
`

const NavHeader = styled.header`
    animation: ${keyframeReveal} var(--animation-reveal);
    margin-bottom: 2rem;
`

type Props = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps 

const Navigation = (props: Props) => {
    const { user } = props

    const { username, isAdmin } = user

    if (!username) return null

    return (
        <NavHeader>
            <Skip href='#main'>Skip to main content</Skip>
            <nav>
                <NavList>
                    <li><NavLink to={RoutePath.BASE}>Apoorv Mote</NavLink></li>
                    <li><NavLink to={isAdmin ? RoutePath.ISSUES_LIST : RoutePath.ISSUES_PAGE_1}>Issues</NavLink></li>
                    {isAdmin && <li><NavLink to={RoutePath.CUSTOMERS}>Manage Customers</NavLink></li>}
                    <li><NavLink to={RoutePath.PROFILE}>Profile</NavLink></li>
                </NavList>
            </nav>
        </NavHeader>
    )
}

const mapStateToProps = (state: RootState) => ({
    user: state.auth.user
})

const mapDispatchToProps = {
    signOut: signOutAsync.request
}

export default connect(mapStateToProps, mapDispatchToProps)(Navigation)
