import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Main } from './styles'
import { RoutePath } from '../types/routePath'

interface Props {
    
}

const PageNotFound: React.FC<Props> = () => {

    useEffect(() => {

        document.title = 'Page not found | Apoorv Mote'

    }, [])

    return (
        <Main>
            <p>Page does not exist</p>
            <Link to={RoutePath.BASE}>Go back to homepage.</Link>
        </Main>
    )
}

export default PageNotFound
