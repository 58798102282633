export enum RoutePath {
    BASE = '/',
    LOGIN = '/login',
    FORGOT_PASSWORD = '/forgot',
    NEW_PASSWORD= '/password/new',
    CUSTOMERS = '/customers',
    PROFILE = '/profile',
    CONFIRM = '/confirm',
    REGISTER = '/register',
    ISSUES_LIST = '/issues',
    ISSUES_PAGE = '/issues/page/:pageNumber',
    ISSUES_PAGE_BASE = '/issues/page/',
    ISSUES_PAGE_1 = '/issues/page/1',
    ISSUE_SINGLE = '/issue/:issueID',
    ISSUE_SINGLE_BASE = '/issue/',
    NEW_ISSUE = '/issues/new',
    CUSTOMER_ISSUES_LIST = '/issues/customer/:customerID/:pageNumber',
    CUSTOMER_ISSUES_BASE = '/issues/customer/',
}

export enum ResourceType {
    ISSUES = 'Issues',
    BILLING = 'Billing'
}