import styled from "styled-components";

export const TableHeader = styled.th.attrs(() => ({ scope:'col' }))`
    position: sticky;
    top: 0;
`

interface iTable {
    isTDCenter: boolean
}

export const Table = styled.table<iTable>`
    border-collapse: collapse;
    border: 1px solid var(--color-primary);
    width: 100%;

    caption {
        font-family: 'Playfair Display', serif;
        font-weight: 700;
        margin: 1.5rem 0;
        font-size: max(30px, 4rem);
    }

    th, td {
        padding: 1rem 2rem;
        border: none;
    }

    td {
        ${props => props.isTDCenter && 'text-align: center;'}
    }

    th {
        background-color: var(--color-primary);
        color: var(--white);
    }

    tbody tr:not(:last-child) th {
        border-bottom: 1px solid var(--white);
    }

    tbody tr:first-child th {
        border-top: 1px solid var(--white);
    }

    tr:nth-of-type(even) {
        background-color: var(--color-primary-bg);
    }
`

export const DataList = styled.dl`
    border: 1px solid var(--color-primary);
    border-radius: 5px;
    overflow: hidden;

    &:not(:last-child) {
        margin-bottom: 4rem;
    }

    dt, dd {
        text-align: center;
        padding: 1rem;
    }

    dt {
        background-color: var(--color-primary-bg);
    }
`

export const TableBorder = styled.div`
    padding: 1rem;
    border: 1px solid var(--color-primary);
    &:not(:last-child) {
        margin-bottom: 2rem;
    }
`

export const DLSection = styled.section`
    padding: 1rem;
    border: 1px solid var(--color-primary);
    &:not(:last-child) {
        margin-bottom: 2rem;
    }
`

export const BadgeList = styled.ul`
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    margin: 1rem 0;

    li {
        padding: 5px;
        background-color: #eee;
        margin-right: 1rem;
    }
`