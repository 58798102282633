import { LoginInput, User, ForgotPasswordInput, NewPasswordInput } from "MyTypes";
import Auth from '@aws-amplify/auth'
import history from "../../history";
import { RoutePath } from "../../types/routePath";

export async function login(input: LoginInput) {
    
    const { email, password } = input

    let response: any

    try {

        response = await Auth.signIn(email.toLowerCase(), password)

    } catch (err) {

        const { message } = err

        return typeof message === 'string' ? message : 'Unknown error'        
    }

    const { attributes, username } = response

    const { given_name, family_name } = attributes

    const userEmail = attributes.email

    const isAdmin = attributes["custom:isAdmin"] === "1" ? true : false

    if (typeof username === 'string' && typeof userEmail === 'string' && typeof given_name === 'string' && typeof family_name === 'string') {

        const user: User = { username, email: userEmail, firstName: given_name, lastName: family_name, isAdmin }

        history.push(RoutePath.BASE)
        
        return user
    }

    return 'converting user data failed'
}

export async function signOut() {
    
    try {

        await Auth.signOut({ global: true })

        return undefined

    } catch (err) {

        const { message } = err

        return typeof message === 'string' ? message : 'Unknown error'
    }
}

export async function currentUser() {
    
    let response: any

    try {

        response = await Auth.currentAuthenticatedUser()

    } catch(err) {

        history.push(RoutePath.LOGIN)

        return err as string
    }

    const { username, attributes } = response

    const { email, given_name, family_name } = attributes

    const isAdmin = attributes["custom:isAdmin"] === "1" ? true : false

    if (typeof username === 'string' && typeof email === 'string' && typeof given_name === 'string' && typeof family_name === 'string') {

        const user: User = { username, email, firstName: given_name, lastName: family_name, isAdmin }

        return user
    }

    return 'converting user data failed'
}

export async function forgotPassword(input: ForgotPasswordInput) {
    
    const { email } = input

    try {

        await Auth.forgotPassword(email.toLowerCase())

        history.push({ pathname: RoutePath.NEW_PASSWORD, state: { email }})

        return undefined

    } catch(err) {

        const { message } = err

        return typeof message === 'string' ? message : 'Unknown error'
    }
}

export async function newPassword(input: NewPasswordInput) {
    
    const { email, code, password } = input

    try {

        await Auth.forgotPasswordSubmit(email, code, password)

        history.push(RoutePath.LOGIN)

        return undefined

    } catch(err) {

        const { message } = err

        return typeof message === 'string' ? message : 'Unknown error'
    }
}