import styled from "styled-components";

const Button = styled.button`
    padding: 1rem 2rem;
    border: none;
    color: var(--white);
    border-radius: 5px;
    font-size: inherit;
    transform: translateY(-.3rem);
    border: 2px solid transparent;

    &:disabled {
        background-color: var(--light-gray);
        color: var(--dark-gray);
    }

    &:active, &:focus {
        transform: translateY(0);
    }
`

export const PrimaryButton = styled(Button)`
    background-color: var(--color-primary);
`

export const DangerButton = styled(Button)`
    background-color: var(--color-error-bg-dark);
`

export const SuccessButton = styled(Button)`
    background-color: var(--color-success);
`

export const ButtonGroup = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 2rem;
`

export const MarginButton = styled(PrimaryButton)`
    margin: 1rem 0;
`