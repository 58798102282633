import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { RootState } from 'typesafe-actions'
import { forgotPasswordAsync } from '../../redux/auth/actions'
import { object, string } from 'yup'
import { ForgotPasswordInput } from 'MyTypes'
import { AuthMain, Heading1, PrimaryButton, GridForm, MyTextInput } from '../styles'
import ErrorAlert from '../utilities/ErrorAlert'
import { Formik } from 'formik'

type Props = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps

const ForgotPasswordPage: React.FC<Props> = (props) => {
    
    const { err, isForgot, forgotPassword } = props

    useEffect(() => {

        document.title = 'Forgot Password | Apoorv Mote'

    }, [])

    const schema = object<ForgotPasswordInput>({
        email: string()
            .email('Invalid email')
            .required('Email is required')
    })

    const initialValues: ForgotPasswordInput = { email: '' }

    return (
        <AuthMain>
            <Heading1>Forgot Password</Heading1>
            <ErrorAlert err={err} />
            <Formik
                validationSchema={schema}
                initialValues={initialValues}
                onSubmit={values => {forgotPassword(values)}}
            >
                {({ values, errors }) => (
                    <GridForm>
                        <MyTextInput type='email' name='email' label='Email' errorID='emailError' required autoFocus />
                        <PrimaryButton type='submit' disabled={(values.email === '') || (!!errors.email) || (isForgot)}>
                            {isForgot ? 'Loading...' : 'Send Code'}
                        </PrimaryButton>
                    </GridForm>
                )}
            </Formik>
        </AuthMain>
    )
}

const mapStateToProps = (state: RootState) => ({
    err: state.auth.authError,
    isForgot: state.auth.isForgot
})

const mapDispatchToProps = {
    forgotPassword: forgotPasswordAsync.request
}

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordPage)
