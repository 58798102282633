import { RootState } from "typesafe-actions";
import { createSelector } from "reselect";
import { sortCommentsByCreatedAt } from "../../components/utilities/helper";

const comments = (state: RootState) => state.comment.comments

const activeIssue = (state: RootState) => state.issue.activeIssue

export const issueComments = createSelector(comments, activeIssue, (items, issueID) => {
    
    const comments = items.filter(item => {
    
        const issue = item.issue

        if (issue) {
            
            return issue.id === issueID
        }

        return false
    })

    return sortCommentsByCreatedAt(comments)
})