import React from 'react'
import { Router } from 'react-router-dom'
import history from '../history'
import Routes from './Routes'
import { GlobalStyle } from './styles'
import Navigation from './Navigation'

interface Props {
  
}

const App = (props: Props) => {
  return (
    <Router history={history}>
      <Navigation />
      <Routes />
      <GlobalStyle />
    </Router>
  )
}

export default App
