import React, { useEffect } from 'react'
import { RootState } from 'typesafe-actions'
import { newPasswordAsync } from '../../redux/auth/actions'
import { connect } from 'react-redux'
import { object, string, ref } from 'yup'
import { NewPasswordInput } from 'MyTypes'
import { useLocation } from 'react-router-dom'
import { AuthMain, Heading1, FormGroup, PrimaryButton, MyTextInput, GridForm } from '../styles'
import ErrorAlert from '../utilities/ErrorAlert'
import { Formik } from 'formik'

type Props = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps

const NewPasswordPage: React.FC<Props> = (props) => {

    const { err, isNewPassword, newPassword } = props
    
    useEffect(() => {

        document.title = 'New Password | Apoorv Mote'

    }, [])

    const schema = object<NewPasswordInput>({
        email: string(),
        code: string()
            .required('Code is required'),
        password: string()
            .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{6,16})(?!.*\s)/, 'Password must include min of 1 lower case, 1 upper case, 1 number & should be between 6-16 characters with no spaces')
            .required('Password is required'),
        confirmPassword: string()
            .oneOf([ref('password'), null], 'Password must match')
            .required('Confirm Password is required')
    })

    const state: any | undefined = useLocation().state

    const optString: string | undefined = state?.email

    const email = optString ?? 'Unknown email'

    const initialValues: NewPasswordInput = { email, code: '', password: '', confirmPassword: '' }

    return (
        <AuthMain>
            <Heading1>Create new password</Heading1>
            <ErrorAlert err={err} />
            <Formik
                validationSchema={schema}
                initialValues={initialValues}
                onSubmit={values => {newPassword(values)}}
            >
                {({ values, errors }) => {

                    const disabled = (values.code === '') || (!!errors.code) || (values.password === '') || (!!errors.password) || (values.confirmPassword === '') || (!!errors.confirmPassword) || isNewPassword

                    return (
                        <GridForm>
                            <FormGroup err={false}>
                                <label>Email</label>
                                <label>{values.email}</label>
                            </FormGroup>
                            <MyTextInput name='code' type='text' label='Code from email' errorID='codeError' required />
                            <MyTextInput name='password' type='password' label='Password' errorID='passwordError' required />
                            <MyTextInput name='confirmPassword' type='password' label='Confirm Password' errorID='confirmPasswordError' required />
                            <PrimaryButton type='submit' disabled={disabled}>
                                {isNewPassword ? 'Loading...' : 'Submit'}
                            </PrimaryButton>
                        </GridForm>
                    )
                }}
            </Formik>
        </AuthMain>
    )
}

const mapStateToProps = (state: RootState) => ({
    err: state.auth.authError,
    isNewPassword: state.auth.isNewPassword
})

const mapDispatchToProps = {
    newPassword: newPasswordAsync.request
}

export default connect(mapStateToProps, mapDispatchToProps)(NewPasswordPage)
