import styled, { keyframes } from 'styled-components'

const spin = keyframes`
    0% { transform: rotate(0deg) }
    100% { transform: rotate(360deg) }
`

export const Loader = styled.div`
    margin: 1rem auto;
    border: 2rem solid var(--light-gray);
    border-top: 2rem solid var(--color-primary);
    border-radius: 50%;
    width: 20rem;
    height: 20rem;
    animation: ${spin} 2s linear infinite;
`

export const keyframeReveal = keyframes`
    0% {
        transform: translateY(5rem);
        opacity: 0
    }
    
    100% {
        transform: translateY(0rem);
        opacity: 1
    }
`

export const Main = styled.main.attrs(() => ({ id: 'main' }))`
    animation: ${keyframeReveal} var(--animation-reveal);
`

export const AuthMain = styled(Main)`
    margin: 1.5rem auto;
    max-width: 500px;
`