import { createReducer } from "typesafe-actions";
import { listCustomersAsync, addAdminAsync, removeAdminAsync, enableCustomerAsync, disableCustomerAsync, activateCustomer } from "./actions";
import { signOutAsync } from "../auth/actions";
import { Customer } from "MyTypes";
import { combineReducers } from "redux";

const isFirstCustomerReducer = createReducer(true)
.handleAction([listCustomersAsync.success], (state, action) => false)
.handleAction([signOutAsync.success], (state, action) => true)

const isAddRemoveAdminReducer = createReducer(false)
.handleAction([addAdminAsync.request, removeAdminAsync.request], (state, action) => true)
.handleAction([addAdminAsync.success, addAdminAsync.failure, removeAdminAsync.success, removeAdminAsync.failure], (state, action) => false)

const isEnableDisableCustomerReducer = createReducer(false)
.handleAction([enableCustomerAsync.request, disableCustomerAsync.request], (state, action) => true)
.handleAction([enableCustomerAsync.success, enableCustomerAsync.failure, disableCustomerAsync.success, disableCustomerAsync.failure], (state, action) => false)

const activeCustomerReducer = createReducer('')
.handleAction([activateCustomer], (state, action) => action.payload)

const indexByUsername = (customers: Customer[], username: string): number => {

    return customers.findIndex(item => item.username === username)
}

const initialCustomers: Customer[] = []

const customersReducer = createReducer(initialCustomers)
.handleAction([listCustomersAsync.success], (state, action) => action.payload)
.handleAction([signOutAsync.success], (state, action) => initialCustomers)
.handleAction([enableCustomerAsync.success], (state, action) => {
    const customers = [...state]
    const index = indexByUsername(customers, action.payload)
    if (index === -1) return state
    customers[index].isEnabled = true
    return customers
})
.handleAction([disableCustomerAsync.success], (state, action) => {
    const customers = [...state]
    const index = indexByUsername(customers, action.payload)
    if (index === -1) return state
    customers[index].isEnabled = false
    return customers
})
.handleAction([addAdminAsync.success], (state, action) => {
    const customers = [...state]
    const index = indexByUsername(customers, action.payload)
    if (index === -1) return state
    customers[index].isAdmin = true
    return customers
})
.handleAction([removeAdminAsync.success], (state, action) => {
    const customers = [...state]
    const index = indexByUsername(customers, action.payload)
    if (index === -1) return state
    customers[index].isAdmin = false
    return customers
})

const emptyError: string = ''

const customerErrorReducer = createReducer(emptyError)
.handleAction([listCustomersAsync.failure, addAdminAsync.failure, removeAdminAsync.failure, enableCustomerAsync.failure, disableCustomerAsync.failure], (state, action) => action.payload)
.handleAction([listCustomersAsync.success, addAdminAsync.success, removeAdminAsync.success, enableCustomerAsync.success, disableCustomerAsync.success], (state, action) => emptyError)

const customerReducer = combineReducers({
    isFirstCustomer: isFirstCustomerReducer,
    isEnableDisableCustomer: isEnableDisableCustomerReducer,
    isAddRemoveAdmin: isAddRemoveAdminReducer,
    customers: customersReducer,
    customerError: customerErrorReducer,
    activeCustomer: activeCustomerReducer
})

export default customerReducer