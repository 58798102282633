import { Epic } from "redux-observable"
import { RootAction, RootState, Services, isActionOf } from "typesafe-actions"
import { filter, mergeMap, map, catchError } from "rxjs/operators"
import { newCommentAsync, editCommentAsync, removeCommentAsync, listCommentAsync, listCustomerCommentAsync } from "./actions"
import { from, of } from "rxjs"

const warning: string = 'refresh the browser to reset'

export const newCommentEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { comment }) =>
action$.pipe(
    filter(isActionOf(newCommentAsync.request)),
    mergeMap((action) =>
        from(comment.newComment(action.payload)).pipe(
            map(res => typeof res === 'string' ? newCommentAsync.failure(res) : newCommentAsync.success(res)),
            catchError((message: string) => of(newCommentAsync.failure(warning + message)))
        )
    )
)

export const editCommentEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { comment }) =>
action$.pipe(
    filter(isActionOf(editCommentAsync.request)),
    mergeMap((action) =>
        from(comment.editComment(action.payload)).pipe(
            map(res => typeof res === 'string' ? editCommentAsync.failure(res) : editCommentAsync.success(res)),
            catchError((message: string) => of(editCommentAsync.failure(warning + message)))
        )
    )
)

export const removeCommentEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { comment }) =>
action$.pipe(
    filter(isActionOf(removeCommentAsync.request)),
    mergeMap((action) =>
        from(comment.removeComment(action.payload)).pipe(
            map(res => res === action.payload ? removeCommentAsync.success(res) : removeCommentAsync.failure(res)),
            catchError((message: string) => of(removeCommentAsync.failure(warning + message)))
        )
    )
)

export const listCommentEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { comment }) =>
action$.pipe(
    filter(isActionOf(listCommentAsync.request)),
    mergeMap(() =>
        from(comment.listComments()).pipe(
            map(res => typeof res === 'string' ? listCommentAsync.failure(res) : listCommentAsync.success(res)),
            catchError((message: string) => of(listCommentAsync.failure(warning + message)))
        )
    )
)

export const listCustomerCommentEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { comment }) =>
action$.pipe(
    filter(isActionOf(listCustomerCommentAsync.request)),
    mergeMap((action) =>
        from(comment.listCustomerComments(action.payload)).pipe(
            map(res => typeof res === 'string' ? listCustomerCommentAsync.failure(res) : listCustomerCommentAsync.success(res)),
            catchError((message: string) => of(listCustomerCommentAsync.failure(warning + message)))
        )
    )
)