import React from 'react'
import { EditorState } from 'draft-js';
import StyleButton from './StyleButton';

interface Props {
    editorState: EditorState
    onToggle: (style: string) => void
}

const InlineStyleControls = (props: Props) => {

    const { editorState, onToggle } = props

    const currentStyle = editorState.getCurrentInlineStyle()

    var INLINE_STYLES = [
        {label: 'Bold', style: 'BOLD'},
        {label: 'Italic', style: 'ITALIC'},
        {label: 'Industry Term', style: 'CODE'},
    ];

    return (
        <div>
            {INLINE_STYLES.map(type => 
                <StyleButton 
                    key={type.label}
                    active={currentStyle.has(type.style)}
                    label={type.label}
                    onToggle={onToggle}
                    style={type.style}
                />    
            )}
        </div>
    )
}

export default InlineStyleControls
