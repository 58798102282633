import styled from "styled-components";
import React from 'react'
import { Form, useField } from 'formik'
// import { dateToString } from '../utilities/helper';


interface iFormGroup {
    err: boolean
}

export const FormGroup = styled.div<iFormGroup>`
    display: grid;
    row-gap: .5rem;

    label span {
        color: var(--color-error-bg-dark);
    }

    label, input, select {
        font-size: max(16px, 2rem);
    }

    input, select {
        border-radius: 5px;
        border: 1px solid var(--dark-gray);
        padding: 1rem .5rem;
        ${props => props.err === true ? 'border-color: var(--color-error-fg);' : ''}
        ${props => props.err === true ? 'background-color: var(--color-error-bg-light);' : ''}
    }

    strong {
        background-color: var(--color-error-bg-dark);
        color: var(--white);
        margin-top: 1rem;
        padding: 1rem;
        position: relative;
        border-radius: 5px;

        &::before {
            content: '';
            width: 0;
            height: 0;
            position: absolute;
            border-left: 1.5rem solid transparent;
            border-right: 1.5rem solid transparent;
            border-bottom: 1.5rem solid var(--color-error-bg-dark);
            top: -1.5rem;
        }
    }
`

export const GridForm = styled(Form)`
    margin: 1rem 0;
    display: grid;
    row-gap: 1rem;
`

export const DraftForm = styled.form`
    margin: 1rem 0;
    display: grid;
    row-gap: 1rem;
`

export const FieldSet = styled.fieldset`
    padding: 1rem;
    border-radius: 5px;
    border-color: var(--color-primary);
`

export function MyTextInput({ label, errorID, ...props}: any) {
    
    const [field, meta] = useField(props)

    const invalid = meta.value === meta.initialValue || !!meta.error

    return (
        <FormGroup err={(meta.touched ?? false) && (!!meta.error)}>
            <label htmlFor={props.name}>{label} <span>*</span></label>
            <input 
                id={props.name}
                aria-describedby={errorID}
                aria-invalid={invalid}
                {...field}
                {...props}
            />
            {(meta.touched && !!meta.error) && <strong id={errorID} role='alert'>{meta.error}</strong>}
        </FormGroup>
    )
}

// export function MyDateInput({ label, errorID, ...props}: any) {
    
//     const [field, meta] = useField(props)

//     const invalid = meta.value === meta.initialValue || !!meta.error

//     return (
//         <FormGroup err={(meta.touched ?? false) && (!!meta.error)}>
//             <label htmlFor={props.name}>{label} <span>*</span></label>
//             <input 
//                 id={props.name}
//                 aria-describedby={errorID}
//                 aria-invalid={invalid}
//                 {...field}
//                 {...props}
//                 value={dateToString(meta.value)}
//             />
//             {(meta.touched && !!meta.error) && <strong id={errorID} role='alert'>{meta.error}</strong>}
//         </FormGroup>
//     )
// }

export function MySelectInput({ label, errorID, ...props}: any) {
    
    const [field, meta] = useField(props)

    const invalid = meta.value === meta.initialValue || !!meta.error

    return (
        <FormGroup err={(meta.touched ?? false) && (!!meta.error)}>
            <label htmlFor={props.name}>{label} <span>*</span></label>
            <select 
                id={props.name}
                aria-describedby={errorID}
                aria-invalid={invalid}
                {...field}
                {...props}
            />
            {(meta.touched && !!meta.error) && <strong id={errorID} role='alert'>{meta.error}</strong>}
        </FormGroup>
    )
}

export const RadioGroup = styled.div`
    display: flex;
    justify-content: space-around;
    margin: 1rem 0;
`

export const ThreeColumnGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 1rem;
`

export const LooseError = styled.strong`
    background-color: var(--color-error-bg-dark);
    color: var(--white);
    margin-top: 1rem;
    padding: 1rem;
    border-radius: 5px;
`