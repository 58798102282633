import { createAsyncAction, createAction } from "typesafe-actions";
import { iIssue, newIssueInput, newIssueOutput } from 'MyTypes'

export const newIssueAsync = createAsyncAction(
    'NEW_ISSUE_REQUEST',
    'NEW_ISSUE_SUCCESS',
    'NEW_ISSUE_FAILURE',
)<newIssueInput, newIssueOutput, string>()

export const editIssueAsync = createAsyncAction(
    'EDIT_ISSUE_REQUEST',
    'EDIT_ISSUE_SUCCESS',
    'EDIT_ISSUE_FAILURE',
)<iIssue, iIssue, string>()

export const listIssueAsync = createAsyncAction(
    'LIST_ISSUE_REQUEST',
    'LIST_ISSUE_SUCCESS',
    'LIST_ISSUE_FAILURE',
)<undefined, iIssue[], string>()

export const listCustomerIssueAsync = createAsyncAction(
    'LIST_CUSTOMER_ISSUE_REQUEST',
    'LIST_CUSTOMER_ISSUE_SUCCESS',
    'LIST_CUSTOMER_ISSUE_FAILURE',
)<string, iIssue[], string>()

export const activeIssue = createAction('ACTIVATE_ISSUE')<string>()