import React, { Fragment, useState } from 'react'
import styled from 'styled-components'
import { Editor, EditorState, DraftStyleMap, convertFromRaw, convertToRaw } from 'draft-js'
import { medDateFrom } from '../helper'
import { PrimaryButton, DraftForm, FormGroup, ButtonGroup, DangerButton } from '../../styles'
import { FormikProps, withFormik, FormikErrors } from 'formik'
import RichEditorField from '../richEditor/RichEditorField'
import { iComment } from 'MyTypes'
import decorator from '../EditorLink'
import { Link } from 'react-router-dom'
import { RoutePath } from '../../../types/routePath'

const RootBorder = styled.div`
    border: 1px solid var(--black);
    padding: 1rem;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    margin-bottom: 1rem;
`

const UsernameBox = styled.div`
    padding: 1rem;
    border: 1px solid var(--black);
    border-bottom: none;
    background-color: #eee;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    margin-top: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
`

interface CommentFormInput {
    content: EditorState
}

const InnerForm = (props: FormikProps<CommentFormInput> & FormProps) => {

    const { values, touched, errors, setFieldValue, handleSubmit, setFieldTouched, changeIsEditing } = props

    const isContentError = !!touched.content && !!errors.content

    const invalidContent = !values.content.getCurrentContent().hasText()

    const disabled = isContentError || invalidContent

    return (
        <DraftForm onSubmit={handleSubmit}>
            <FormGroup err={isContentError}>
                <label>Edit Comment</label>
                <RichEditorField
                    editorState={values.content}
                    onChange={setFieldValue}
                    err={isContentError}
                    onFocus={setFieldTouched}
                    isReadOnly={false}
                />
                {isContentError && <strong role='alert'>{errors.content}</strong>}
            </FormGroup>
            <ButtonGroup>
                <DangerButton type='button' onClick={() => changeIsEditing(false)}>
                    Cancel
                </DangerButton>
                <PrimaryButton type='submit' disabled={disabled}>
                    Update Comment
                </PrimaryButton>
            </ButtonGroup>
        </DraftForm>
    )
}

interface FormProps {
    comment: iComment
    editComment: (input: iComment) => void
    changeIsEditing: (isEditing: boolean) => void
}

const EnhancedForm = withFormik<FormProps, CommentFormInput>({
    mapPropsToValues: (props) => {
        
        const contentObject = JSON.parse(props.comment.content)

        const contentState = convertFromRaw(contentObject)

        const editorState = EditorState.createWithContent(contentState, decorator)

        return { content: editorState }
    },
    validate: values => {
        let errors: FormikErrors<CommentFormInput> = {}
        if (!values.content.getCurrentContent().hasText()) {
            errors.content = 'Comment cannot be empty'
        }
        return errors
    },
    handleSubmit: (values, formikBag) => {
        const content = JSON.stringify(convertToRaw(values.content.getCurrentContent()))
        const stuff = formikBag.props.comment
        const editedComment: iComment = { id: stuff.id, issue: stuff.issue, content: stuff.content, updatedAt: stuff.updatedAt, owner: stuff.owner, author: stuff.author, createdAt: stuff.createdAt }
        editedComment.content = content
        formikBag.props.editComment(editedComment)
        formikBag.props.changeIsEditing(false)
    }
})(InnerForm)

interface Props {
    editorState: EditorState
    name: string
    updatedAt: string
    isAuthor: boolean
    comment: iComment
    issueID: string
    editComment: (comment: iComment) => void
}

const ReadOnlyEditor = (props: Props) => {

    const { editorState, name, updatedAt, isAuthor, comment, editComment, issueID } = props

    const [isEditing, setIsEditing] = useState(false)

    const styleMap: DraftStyleMap = {
        CODE: {
            backgroundColor: 'rgba(0,0,0, 0.1)',
            display: 'inline-block',
            fontSize: '2rem',
            padding: 2,
            paddingRight: 5,
            paddingLeft: 5,
        }
    }

    const date = medDateFrom(updatedAt)

    function changeIsEditing(isEditing: boolean) {
        
        setIsEditing(isEditing)
    }

    if (isEditing) {

        return <EnhancedForm comment={comment} editComment={editComment} changeIsEditing={changeIsEditing} />
    }

    return (
        <Fragment>
            <UsernameBox>
                <p>
                    {issueID !== '' ? 
                    <Link to={`${RoutePath.ISSUE_SINGLE_BASE}${issueID}`}>
                        {name} commented on {date}
                    </Link> : 
                    `${name} commented on ${date}`
                    }
                </p>
                {isAuthor && <PrimaryButton onClick={() => setIsEditing(true)}>Edit</PrimaryButton>} 
            </UsernameBox>
            <RootBorder>
                <Editor 
                    editorState={editorState} 
                    onChange={() => undefined} 
                    readOnly={true}
                    customStyleMap={styleMap} 
                />
            </RootBorder>
        </Fragment>
    )
}

export default ReadOnlyEditor
