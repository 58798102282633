import styled from "styled-components";

export const Heading1 = styled.h1`
    margin: 1.5rem 0;
    font-size: max(30px, 4rem);
`

export const CenterH1 = styled(Heading1)`
    text-align: center;
`

export const Heading2 = styled.h2`
    margin: 1.5rem 0;
    font-size: max(26px, 3.5rem);
`

export const CenterH2 = styled(Heading2)`
    text-align: center;
`

export const Heading3 = styled.h3`
    margin: 1.5rem 0;
    font-size: max(22px, 3rem);
`

export const CenterH3 = styled(Heading3)`
    text-align: center;
`