import React from 'react'
import StyleButton from './StyleButton';
import { EditorState } from 'draft-js';

interface Props {
    editorState: EditorState
    onToggle: (style: string) => void
}



const BlockStyleControls = (props: Props) => {

    const { editorState, onToggle } = props

    const selection = editorState.getSelection()

    const blockType = editorState.getCurrentContent().getBlockForKey(selection.getStartKey()).getType()

    const BLOCK_TYPES = [
        {label: 'Heading 1', style: 'header-one'},
        {label: 'Heading 2', style: 'header-two'},
        {label: 'Quote', style: 'blockquote'},
        {label: 'Bullet List', style: 'unordered-list-item'},
        {label: 'Number List', style: 'ordered-list-item'},
      ];
    
    return (
        <div>
            {BLOCK_TYPES.map(type => 
                <StyleButton 
                    key={type.label}
                    active={type.style === blockType}
                    onToggle={onToggle}
                    style={type.style}
                    label={type.label}
                />
            )}
        </div>
    )
}

export default BlockStyleControls
