import { Epic } from "redux-observable"
import { RootAction, RootState, Services, isActionOf } from "typesafe-actions"
import { filter, mergeMap, map, catchError } from "rxjs/operators"
import { newIssueAsync, editIssueAsync, listIssueAsync, listCustomerIssueAsync } from "./actions"
import { from, of } from "rxjs"

const warning: string = 'refresh the browser to reset'

export const newIssueEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { issue }) => 
action$.pipe(
    filter(isActionOf(newIssueAsync.request)),
    mergeMap((action) =>
        from(issue.newIssue(action.payload)).pipe(
            map(res => typeof res === 'string' ? newIssueAsync.failure(res) : newIssueAsync.success(res)),
            catchError((message: string) => of(newIssueAsync.failure(warning + message)))
        )
    )
)

export const editIssueEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { issue }) => 
action$.pipe(
    filter(isActionOf(editIssueAsync.request)),
    mergeMap((action) =>
        from(issue.editIssue(action.payload)).pipe(
            map(res => typeof res === 'string' ? editIssueAsync.failure(res) : editIssueAsync.success(res)),
            catchError((message: string) => of(editIssueAsync.failure(warning + message)))
        )
    )
)

export const listIssueEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { issue }) => 
action$.pipe(
    filter(isActionOf(listIssueAsync.request)),
    mergeMap(() =>
        from(issue.listIssues()).pipe(
            map(res => typeof res === 'string' ? listIssueAsync.failure(res) : listIssueAsync.success(res)),
            catchError((message: string) => of(listIssueAsync.failure(warning + message)))
        )
    )
)

export const listCustomerIssueEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { issue }) => 
action$.pipe(
    filter(isActionOf(listCustomerIssueAsync.request)),
    mergeMap((action) =>
        from(issue.listCustomerIssues(action.payload)).pipe(
            map(res => typeof res === 'string' ? listCustomerIssueAsync.failure(res) : listCustomerIssueAsync.success(res)),
            catchError((message: string) => of(listCustomerIssueAsync.failure(warning + message)))
        )
    )
)