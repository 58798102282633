import { DataStore } from "@aws-amplify/datastore";
import { Issue, Comment } from "../../models";
import { newIssueInput, newIssueOutput, iComment } from 'MyTypes'
import history from "../../history";
import { RoutePath } from "../../types/routePath";
import { iIssue } from 'MyTypes'

export async function newIssue(input: newIssueInput) {
    
    const { title, owner, author, isOpen, isPinned, label, content } = input

    try {

        const results = await DataStore.save(new Issue({
            title, owner, author, isPinned, isOpen, label
        }))

        const comment = await DataStore.save(new Comment({
            owner, author, content, issue: results
        }))

        const item: newIssueOutput = {
            issue: results as iIssue,
            comment: comment as iComment
        }

        if (author === owner) {

            history.push(RoutePath.ISSUES_PAGE_1)

        } else {
            
            history.push(`${RoutePath.CUSTOMER_ISSUES_BASE}${owner}/1`)
        }

        return item

    } catch (err) {

        const { message } = err

        return typeof message === 'string' ? message : 'Unknown error'
    }
}

export async function editIssue(input: iIssue) {
    
    const { id, title, owner, author, isOpen, isPinned, label } = input

    try {

        const original = await DataStore.query(Issue, id)

        const results = await DataStore.save(
            Issue.copyOf(original, updated => {
                updated.title = title 
                updated.isPinned = isPinned 
                updated.isOpen = isOpen
                updated.label = label
            })
        )

        if (author === owner) {

            history.push(RoutePath.ISSUES_PAGE_1)

        } else {
            
            history.push(`${RoutePath.CUSTOMER_ISSUES_BASE}${owner}/1`)
        }

        return results as iIssue

    } catch (err) {

        const { message } = err

        return typeof message === 'string' ? message : 'Unknown error'
    }
}

export async function listIssues() {
    
    try {

        const results = await DataStore.query(Issue)

        return results as iIssue[]
        
    } catch (err) {

        const { message } = err

        return typeof message === 'string' ? message : 'Unknown error'
    }
}

export async function listCustomerIssues(owner: string) {
    
    try {

        const results = await DataStore.query(Issue, c => c.owner('eq', owner))

        return results as iIssue[]

    } catch (err) {

        const { message } = err

        return typeof message === 'string' ? message : 'Unknown error'
    }
}