import { combineEpics } from 'redux-observable'
import * as authEpics from '../auth/epics'
import * as customersEpics from '../customers/epics'
import * as issuesEpics from '../issues/epics'
import * as commentsEpics from '../comments/epics'

export default combineEpics(
    ...Object.values(authEpics),
    ...Object.values(customersEpics),
    ...Object.values(issuesEpics),
    ...Object.values(commentsEpics)
)