import styled from "styled-components";

export const PaginationList = styled.ul`
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    margin: 1.5rem 0;

    li {
        border: 1px solid var(--color-link);
        margin-bottom: 5px;
        border-radius: 5px;

        &:not(:last-child) {
            margin-right: 5px;
        }

        a {
            display: inline-block;
            padding: 1rem 2rem;
        }
    }
` 

export const PaginationActiveItem = styled.li`
    padding: 1rem 2rem;
    background-color: var(--color-primary-bg);
`