import { newCommentInput, iComment } from "MyTypes";
import { DataStore } from "@aws-amplify/datastore";
import { Comment, Issue } from "../../models";

export async function newComment(input: newCommentInput) {
    
    const { owner, author, content, issueID } = input

    try {

        const now = (new Date()).toISOString()
        
        const original = await DataStore.query(Issue, issueID)
        
        const results = await DataStore.save( new Comment({
            owner, author, content, issue: original, createdAt: now, updatedAt: now
        }))
        
        return results as iComment
        
    } catch (err) {
        
        const { message } = err
        
        return typeof message === 'string' ? message : 'Unknown error'
    }
}

export async function editComment(input: iComment) {
    
    const { id, content } = input
    
    try {

        const now = (new Date()).toISOString()
        
        const original = await DataStore.query(Comment, id)
        
        const results = await DataStore.save( 
            Comment.copyOf(original, updated => {
                updated.content = content
                updated.updatedAt = now
            })
        )
        
        return results as iComment

    } catch (err) {

        const { message } = err

        return typeof message === 'string' ? message : 'Unknown error'
    }
}

export async function removeComment(id: string) {
       
    try {
        
        const original = await DataStore.query(Comment, id)
        
        await DataStore.delete(original)
        
        return id

    } catch (err) {

        const { message } = err

        return typeof message === 'string' ? message : 'Unknown error'
    }
}

export async function listComments() {
       
    try {
        
        const results = await DataStore.query(Comment)
        
        return results as iComment[]

    } catch (err) {

        const { message } = err

        return typeof message === 'string' ? message : 'Unknown error'
    }
}

export async function listCustomerComments(owner: string) {
       
    try {
        
        const results = await DataStore.query(Comment, c => c.owner('eq', owner))
        
        return results as iComment[]

    } catch (err) {

        const { message } = err

        return typeof message === 'string' ? message : 'Unknown error'
    }
}
