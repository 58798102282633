import { createReducer } from "typesafe-actions";
import { loginAsync, currentUserAsync, signOutAsync, forgotPasswordAsync, newPasswordAsync } from "./actions";
import { User } from "MyTypes";
import { combineReducers } from "redux";

const isLoginReducer = createReducer(false)
.handleAction([loginAsync.request], (state, action) => true)
.handleAction([loginAsync.success, loginAsync.failure], (state, action) => false)

const emptyUser: User = {
    username: '', firstName: '', lastName: '', email: '', isAdmin: false
}

const userReducer = createReducer(emptyUser)
.handleAction([loginAsync.success, currentUserAsync.success], (state, action) => action.payload)
.handleAction([signOutAsync.success], (state, action) => emptyUser)

const isForgotReducer = createReducer(false)
.handleAction([forgotPasswordAsync.request], (state, action) => true)
.handleAction([forgotPasswordAsync.success, forgotPasswordAsync.failure], (state, action) => false)

const isNewPasswordReducer = createReducer(false)
.handleAction([newPasswordAsync.request], (state, action) => true)
.handleAction([newPasswordAsync.success, newPasswordAsync.failure], (state, action) => false)

const emptyError: string = ''

const authErrorReducer = createReducer(emptyError)
.handleAction([loginAsync.failure, currentUserAsync.failure, signOutAsync.failure, newPasswordAsync.failure, forgotPasswordAsync.failure], (state, action) => action.payload)
.handleAction([loginAsync.success, currentUserAsync.success, forgotPasswordAsync.success, newPasswordAsync.success], (state, action) => emptyError)

const authReducer = combineReducers({
    isLogin: isLoginReducer,
    user: userReducer,
    isForgot: isForgotReducer,
    isNewPassword: isNewPasswordReducer,
    authError: authErrorReducer
})

export default authReducer
